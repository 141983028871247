import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/core";
import Logo from "../images/logo.png";

const Header = () => {
  const { left, right } = useStaticQuery(graphql`
    query {
      left: sanityNav(title: { eq: "Header / Left" }) {
        links {
          title
          value
        }
      }
      right: sanityNav(title: { eq: "Header / Right" }) {
        links {
          title
          value
        }
      }
    }
  `);

  function handleClick() {
    const navButton = document.getElementById("mobileNav");
    const menuList = document.getElementById("nav");
    const expanded =
      navButton.getAttribute("aria-expanded") === "true" || false;
    navButton.setAttribute("aria-expanded", !expanded);
    menuList.classList.toggle("menu-list-expanded");
    navButton.classList.toggle("is-active");
  }
  return (
    <>
      <nav
        css={css`
          background-color: #2d2d2d;
          color: #ffffff;
          position: sticky;
          top: 0;
          z-index: 100;
          height: 77px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
          @media screen and (max-width: 1080px) {
            height: auto;
            .hamburger {
              padding: 14px 14px;
              display: inline-block;
              cursor: pointer;
              transition-property: opacity, filter;
              transition-duration: 0.15s;
              transition-timing-function: linear;
              font: inherit;
              color: inherit;
              text-transform: none;
              background-color: transparent;
              border: 0;
              margin: 0;
              overflow: visible;
            }
            .hamburger:hover {
              opacity: 0.7;
            }
            .hamburger.is-active:hover {
              opacity: 1;
            }
            .hamburger.is-active .hamburger-inner,
            .hamburger.is-active .hamburger-inner::before,
            .hamburger.is-active .hamburger-inner::after {
              background-color: #fff;
            }
            .hamburger-box {
              width: 44px;
              height: 34px;
              display: inline-block;
              position: relative;
            }
            .hamburger-inner {
              display: block;
              top: 50%;
              margin-top: -2px;
            }
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
              width: 34px;
              height: 4px;
              background-color: #fff;
              border-radius: 4px;
              position: absolute;
              transition-property: transform;
              transition-duration: 0.15s;
              transition-timing-function: ease;
            }
            .hamburger-inner::before,
            .hamburger-inner::after {
              content: "";
              display: block;
            }
            .hamburger-inner::before {
              top: -10px;
            }
            .hamburger-inner::after {
              bottom: -10px;
            }
            .hamburger--collapse .hamburger-inner {
              top: auto;
              bottom: 6px;
              transition-duration: 0.13s;
              transition-delay: 0.13s;
              transition-timing-function: cubic-bezier(
                0.55,
                0.055,
                0.675,
                0.19
              );
            }
            .hamburger--collapse .hamburger-inner::after {
              top: -20px;
              transition: top 0.2s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                opacity 0.1s linear;
            }
            .hamburger--collapse .hamburger-inner::before {
              transition: top 0.12s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            .hamburger--collapse.is-active .hamburger-inner {
              transform: translate3d(0, -10px, 0) rotate(-45deg);
              transition-delay: 0.22s;
              transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            .hamburger--collapse.is-active .hamburger-inner::after {
              top: 0;
              opacity: 0;
              transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }
            .hamburger--collapse.is-active .hamburger-inner::before {
              top: 0;
              transform: rotate(-90deg);
              transition: top 0.1s 0.16s
                  cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
          }
          > div {
            min-width: 1080px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 1080px) {
              min-width: 0px;
              width: 100%;
              align-items: start;
              flex-direction: column;
              position: relative;
            }
          }
          button {
            display: none;
            @media screen and (max-width: 1080px) {
              &[aria-controls="expand-nav"] {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                outline: none;
                cursor: pointer;
              }
            }
          }
          ul {
            display: flex;
            align-items: center;
            @media screen and (max-width: 1080px) {
              display: none;
              flex-direction: column;
              align-items: start;
              &.menu-list-expanded {
                display: block;
                /* height: 100vh; */
              }
            }
          }
          .logo {
            display: flex;
            align-items: center;
            margin: 4px 0 0 8px;
          }
          li {
            & a {
              font-size: 16px;
              letter-spacing: 1px;
              text-decoration: none;
              text-transform: uppercase;
              padding: 10px;
              color: #fff;
              transition-duration: 0.3s;
              position: relative;
            }
            & a:hover::before {
              width: 8px;
              height: 8px;
              background: #3feae6;
              display: block;
              left: 42%;
              bottom: -10%;
              margin-top: -4px;
              content: "";
              position: absolute;
              border-radius: 50%;
            }
            display: block;
            text-align: left;
            @media screen and (max-width: 1080px) {
              padding: 8px;
            }
          }
        `}
      >
        <div>
          <button
            id="mobileNav"
            aria-expanded="false"
            aria-controls="expand-nav"
            onClick={handleClick}
            // hamburger animation class and type
            className="hamburger hamburger--collapse"
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <ul>
            {left.links.map(link => (
              <li key={link.title}>
                <a href={link.value} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="logo">
            <a
              href="https://nanocraftcbd.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Logo}
                alt="Nanocraft CBD's logo"
                css={css`
                  width: 180px;
                  :hover {
                    opacity: 0.5;
                    transition-duration: 0.3s;
                  }
                `}
              />
            </a>
          </div>
          <ul id="nav" className="nav">
            {right.links.map(link => (
              <li key={link.title}>
                <a href={link.value} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
